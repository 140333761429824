import React from 'react'
import '../../Styles/FooterItem.css';
const privacy_policy = () => {
  return (
	<div>
		<div class="privacy-policy">
			<h1>Privacy Policy</h1>
			<p>
				Vkyor Medix Private Ltd., adopted this Privacy Policy with respect to its
				collection, use, storage, and disclosure of personal information.
			</p>

			<h2>Introduction</h2>

			<p>
				We are committed to protecting the privacy of personal information and
				to handling the personal information in a responsible manner in
				accordance with the relevant privacy legislations in the countries of
				our operations (referred to as privacy legislation). This Privacy Policy
				explains how we collect, use and disclose your personal information, how
				you may access that information and how you may seek the correction of
				any information. It also explains how you may make a complaint about a
				breach of privacy legislation. From time to time we may make changes to
				our policy, processes and systems in relation to how we handle your
				personal information. We will update this Privacy Policy to reflect any
				changes. Those changes will be available on our website.
			</p>
			<h2>Collection</h2>

			<p>
				We collect information that is necessary and relevant to providing our
				core services and products, This information may include your name,
				address, date of birth, gender, This information may be stored on our
				computer records system and/or in printed records (collectively,
				information). Wherever practicable we will only collect information from
				you personally. We collect information in various ways, such as over the
				phone or in writing, in person, or over the internet. We will notify you
				or will endeavour to make you aware that information is or has being
				collected. We will endeavour to inform you of the purpose of collection
				and will provide you with access to our privacy policy that contains
				details about access, correction and complaints. We advise that if you
				notify us that you do not wish to provide the information to us we may
				not be able to provide you with the product or service you have asked
				for or give you the level of service you expect.
			</p>
			<h2>Use & Disclosure</h2>

			<p>
				We will treat your personal information as strictly private and
				confidential. We will only use or disclose it for purposes directly
				related to our core businesses, or in ways that you would reasonably
				expect that we may use it in the course of our business. We do not sell,
				trade, or otherwise transfer to outside parties your personally
				identifiable information. This does not include trusted third parties
				who assist us in operating our website, conducting our business, or
				servicing you, so long as those parties agree to keep this information
				confidential and the data is used only for the activities we have asked
				them to perform. We may also release your information when we believe
				release is appropriate to comply with the law, or protect ours or others
				rights, property, or safety. However, non-personally identifiable
				information including statistical data may be provided to other parties
				for marketing, advertising, research or other uses.
			</p>
			<h2>Data Security</h2>

			<p>
				We maintain adequate technical and organizational security measures to
				protect personal data from loss, misuse, and unauthorized access,
				disclosure, alteration, or destruction.
			</p>
			<h2>Access & Corrections</h2>

			<p>
				If you believe that the information we have about you is not accurate,
				complete or up-to-date, we ask that you contact us in writing.
			</p>
			<h2>Complaints</h2>

			If you have a complaint about the privacy of your personal information, we
			request that you contact us in writing, by addressing any complaint to the
			Chief Compliance Officer. Upon receipt of a complaint we will consider the
			details and attempt to resolve it in accordance with our complaints
			handling procedures.
		</div>
	</div>
  )
}

export default privacy_policy